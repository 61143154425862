import React from "react";
import { Link } from "react-router-dom";
import IATALogo from "../assets/iata1.svg";


const Footer = () => {
  return (
    <>
     
      <footer className="footer bg-yellow-1000">
        <div className="footer__inner container container-xl">
          <div className="footer__text-wrap">
            <p>© 2023 Ridewing. All Rights Reserved Kingdom Holidays Group.</p>
            <div className="footer__links-wrap">
              <Link to="/terms-and-conditions" target="_blank">
                Terms
              </Link>
              <Link to="/privacy-policy" target="_blank">
                Privacy
              </Link>
            </div>
          </div>
          <img className="logo__img" src={IATALogo} alt="IATA TIDS" />
        </div>
      </footer>
    </>
  );
};

export default Footer;
