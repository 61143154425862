import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";

const Terms = () => {
  return (
    <div className="flex flex-col  inner-page text-white bg-black ">
      <Navbar />
      <div className="flex flex-col items-center justify-center px-4 overflow-auto">
        <div className="px-2 pt-[100px] md:pt-4 space-x-2 justify-evenly md:w-3/5 self-center pb-[40px]">
          <div>
            <h3 className="text-center font-bold mt-[50px]">
              RIDEWING Terms and conditions of use
            </h3>
            <br />
            <h3>1. Introduction</h3>
            <div className="p-2 text-justify">
              <p>
                These terms and conditions ("Terms") govern your use of the Kingdom Holidays Group
                Ltd website and services ("Services"), including our transfer services. By accessing or
                using the website or Services, you ("you" or "User") agree to be bound by these Terms.
                If you disagree with any part of the Terms, you may not access the website or use the
                Services. </p>
              <br />
            </div>
            <br />
            <h3>2. About Us</h3>
            <div className="p-2 text-justify">
              <p>
                Kingdom Holidays Group Ltd (trading as RIDEWING) is registered in West Drayton,
                United Kingdom, with registration number 11730934 and VAT registration number
                313788488. We operate a global marketplace connecting users with local, licensed
                affiliates who provide Ground transport services Worldwide.</p>
              <br />
            </div>
            <h3>3. Eligibility</h3>
            <div className="p-2 text-justify">
              <p>
                You must be at least 18 years of age to use the website and Services. By using the
                website and Services, you represent and warrant that you are at least 18 years of age
                or have the consent of a legal guardian.</p><br />
            </div>
            <h3>4. Our Role</h3>
            <div className="p-2 text-justify">
              <p>
                Kingdom Holidays Group (trading as RIDEWING) operates a platform for booking travel-
                related services worldwide. We facilitate bookings with local affiliates who are licensed
                by the relevant authorities in each country. While we carefully select our affiliates and
                strive to ensure a high-quality experience, your contract for the services is directly with
                the affiliate, not with Kingdom Holidays Group.</p><br />
            </div>
            <h3>5. Affiliate Contracts and Liability</h3>
            <div className="p-2 text-justify">
              <p>
                When you book a service through our platform, you enter into a contract directly with
                the local affiliate providing that service. Kingdom Holidays Group is not a party to that
                contract and is not responsible for any loss, damage, or injury arising from the services
                provided by the affiliate. Any claims or disputes related to the services must be
                addressed directly with the affiliate.</p><br />
            </div>
            <h3>6. Booking Process</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-disc list-outside">
                <li value={4} key={4} className="text-justify p-2">
                  Placing a Booking: Once you choose a service, follow the booking process on
                  our website. Bookings are subject to availability.
                </li>
                <li value={4} key={4} className="text-justify p-2">
                  Payment: All payments must be made in full at the time of booking. We accept
                  major credit and debit cards.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Confirmation: Upon successful payment, you will receive a booking confirmation
                  via email. This confirmation serves as proof of your reservation.
                </li>
              </ol>
            </div>
            <h3>7. Pricing and Availability</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-disc list-outside">
                <li value={4} key={4} className="text-justify p-2">
                  Prices: All prices on our website are in GBP (British Pounds) unless otherwise
                  stated. Prices are subject to change without notice, but changes will not affect
                  confirmed bookings.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Availability: All services are offered subject to availability. We strive for accuracy,
                  but Kingdom Holidays Group is not responsible for availability changes by third-
                  party affiliates.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Vehicle Variability: Vehicles may vary by country, and their models, features, and
                  availability may differ. If there are issues with the booked vehicle, a replacement
                  of suitable seating capacity will be arranged, subject to availability.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Baggage Allowance: For Airport and Station Transfers, each passenger is
                  allowed one piece of hand luggage and one check-in bag. Additional luggage
                  must be arranged by the guest.</li>
              </ol>
            </div>
            <h3>8. Cancellations and Refunds</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-disc list-outside">
                <li value={4} key={4} className="text-justify p-2">
                  User Cancellations: Cancellation policies vary by affiliate. Please review the
                  specific terms before booking. Some bookings may be non-refundable or subject
                  to cancellation fees.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Affiliate Cancellations: If an affiliate cancels your booking, Kingdom Holidays
                  Group will notify you and arrange an alternative service or provide a full refund.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Refund Policy: Refunds (if applicable) will be processed within 7-10 business
                  days.</li>
              </ol>
            </div>
            <h3>9. Changes to Bookings</h3>
            <div className="p-2 text-justify">
              <p>
                Contact Kingdom Holidays Group to amend your booking. Not all bookings are eligible
                for changes, and fees may apply.</p><br />
            </div>
            <h3>10. User Responsibilities</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-disc list-outside">
                <li value={4} key={4} className="text-justify p-2">
                  Accurate Information: Provide accurate and complete information when booking.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Meeting Requirements: Comply with all service requirements, including arrival
                  times, dress codes, age restrictions, and health or safety regulations.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Travel Documents: Ensure you have the necessary travel documentation.</li>
              </ol>
            </div>
            <h3>11. Limitations of Liability</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-disc list-outside">
                <li value={4} key={4} className="text-justify p-2">
                  Third-Party Services: While we work with licensed affiliates, Kingdom Holidays
                  Group is not liable for injuries, losses, or damages caused by their services.</li>
                <li value={4} key={4} className="text-justify p-2">
                  No Guarantee: Kingdom Holidays Group does not guarantee the accuracy of
                  information on the website or uninterrupted service.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Maximum Liability: Our liability is limited to the amount you paid for the service.</li>
              </ol>
            </div>
            <h3>12. Intellectual Property</h3>
            <div className="p-2 text-justify">
              <p>
                All website content is the intellectual property of Kingdom Holidays Group or its
                licensors. You may not use our content without written permission.</p><br />
            </div>
            <h3>13. Privacy</h3>
            <div className="p-2 text-justify">
              <p>
                Our Privacy Policy explains how we collect, store, and use your personal data.</p><br />
            </div>
            <h3>14. Website Use</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-disc list-outside">
                <li value={4} key={4} className="text-justify p-2">
                  Use Restrictions: Use the website only for lawful purposes and in a way that
                  does not infringe upon the rights of others.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Prohibited Actions: You may not transmit harmful, unlawful, or offensive content;
                  upload malware; or attempt unauthorized access to the website.</li>
                <li value={4} key={4} className="text-justify p-2">
                  Maximum Liability: Our liability is limited to the amount you paid for the service.Compliance: Comply with all applicable laws and regulations when using our
                  website.</li>
              </ol>
            </div>
            <h3>15. Termination</h3>
            <div className="p-2 text-justify">
              <p>
                Kingdom Holidays Group may terminate or suspend your access to the website and
                Services for any violation of these Terms.</p><br />
            </div>
            <h3>16. Amendments</h3>
            <div className="p-2 text-justify">
              <p>
                Kingdom Holidays Group may update these Terms. Changes will be posted on this page
                with an updated "Effective Date." Continued use of the website signifies acceptance of
                the revised Terms.</p><br />
            </div>
            <h3>17. Governing Law</h3>
            <div className="p-2 text-justify">
              <p>
                These Terms are governed by the laws of England and Wales.</p><br />
            </div>
            <h3>18. Contact Us</h3>
            <div className="p-2 content-padding-left">
              <ol className="list-disc list-outside">
                <li value={4} key={4} className="text-justify p-2">
                  Email: <a href="mailto:info@ridewing.co.uk">info@ridewing.co.uk</a></li>
                <li value={4} key={4} className="text-justify p-2">
                  Phone: <a href="tel:+44(0)2084324325">+44(0)2084324325</a></li>
              </ol>
            </div>
            <p>© 2023 RIDEWING. All Rights Reserved Kingdom Holidays Group Ltd.</p>
          </div>
          <br />
          
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
