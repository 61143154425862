import React, { useState } from "react";
import Navbar from "../../common/Navbar";
import BritishIcon from "../../assets/britishIcon.png";
import ImgAirport from "../../assets/airport.webp";
import ImgMeet from "../../assets/meet.webp";
import arrow from '../../assets/arrowr.svg'
import { SERVICE_AIRPORT } from "./constants";
import Footer from "../../common/Footer";
import { useHistory } from 'react-router-dom';

import spairport from '../../assets/spairport.svg'
import sphourly from '../../assets/sphourly.svg'
import spintercity from '../../assets/spintercity.svg'
import spbus from '../../assets/spbus.svg'
import BlogListing from "../Blog/BlogListing";

const Service = () => {
  const history = useHistory();
  return (
    <div className="bg-black">
      <div className="flex flex-col bg-black">
        <Navbar />
        <main className="main ">
          <div>
            <div className="container-xl min-h-[80vh] flex flex-col items-center justify-center mx-auto">
              <div className="text-center flex flex-col mt-[30px] md:mt-[63px] ">
                <span className="text-[34px] md:text-[48px]">Ridewing Services</span>
                {/* <span className="text-[18px] md:text-[24px]">One stop solution for premium airport transfers</span> */}
              </div>

              <div className="flex flex-col py-[60px] gap-[30px] px-[4%] ">

                {/* Airport Transfer */}
                <div className="lg:max-w-[1102px] w-full bg-[#51545B] rounded-[10px] flex flex-wrap-reverse lg:flex-nowrap justify-center items-center p-[15px] md:p-[32px]">
                  <div className="flex flex-col lg:pr-[32px] lg:w-[50%]">
                    <span className="text-[24px] md:text-[30px] font-[700] mt-[20px] lg:mt-0">Airport Transfer</span>
                    <span className="pt-[10px] md:pt-[22px] text-[12px] lg:text-[16px]">Exhausted after a long flight? We’ve got your back. Get to your destination directly from any airport in the UK. Wherever you go, our chauffeurs can track your flight and adjust for any delays.<br />
                      Premium vehicles, high quality service, safe and secure transfer. You can reserve your airport transfer in any city of your choice in the UK through our ridewing website or ridewing app made available for your android or apple device.<br />
                      The booking is simple and straightforward - enter your pickup and destination information. After you have confirmed the payment details, you will receive a confirmation email shortly afterwards. Our services are transparent, and there are no hidden charges.</span>
                  </div>
                  <div className="lg:w-[50%] lg:h-full h-[160px] md:h-[300px] w-full overflow-hidden">
                    <img src={spairport} alt="" className="w-full h-full object-cover " />
                  </div>
                </div>

                {/* Hourly */}
                <div className="lg:max-w-[1102px] w-full bg-[#51545B] rounded-[10px] flex flex-wrap lg:flex-nowrap justify-center items-center p-[15px] md:p-[32px]">
                  <div className="flex lg:w-[50%] lg:h-full h-[160px] md:h-[360px] w-full overflow-hidden">
                    <img src={sphourly} alt="" className="w-full h-full md:h-[360px] object-cover object-left" />
                  </div>
                  <div className="flex flex-col lg:pl-[32px]  lg:w-[50%]">
                    <span className="text-[24px] md:text-[30px] font-[700] mt-[20px] lg:mt-0">Hourly booking London LIMOUSINE service</span>
                    <span className="pt-[10px] md:pt-[22px] text-[12px] lg:text-[16px]">a high class travel experience, or need a premium ride service for whatever reason, we can have a limousine and local chauffeur pick you up anywhere in London . you can enjoy a safe and professional journey with your own personal chauffeur with ridewing limousine service.
                      <br /><br /><br />
                      -Professional drivers-<br />
                      Reach your destination safely and relaxed with a licensed, insured and professional driver.</span>
                  </div>
                </div>

                {/* Intercity transfers */}
                <div className="lg:max-w-[1102px] w-full bg-[#51545B] rounded-[10px] flex flex-wrap-reverse lg:flex-nowrap justify-center items-start p-[15px] md:p-[32px]">
                  <div className="flex flex-col lg:pr-[32px] lg:w-[50%]">
                    <span className="text-[24px] md:text-[30px] font-[700] mt-[20px] lg:mt-0">Intercity transfers</span>
                    <span className="pt-[10px] md:pt-[22px] text-[12px] lg:text-[16px]">The better way between cities
                      comfort rides with our inter-City private car service.
                    </span>
                    <ul className="text-[12px] lg:text-[16px] pl-[20px]" style={{ listStyleType: 'disc' }}>
                      <li>Save time: Door-to-door rides mean no waiting in lines or switching between modes of transportation.</li>
                      <li>Peace of mind: Travel in comfort in a premium vehicle with reliable chauffeur drivers</li>
                      <li>Fixed fares: The price for your journey is fixed, Taxes and tolls are included</li>
                    </ul>
                  </div>
                  <div className="lg:w-[50%] lg:h-full h-[160px] md:h-[300px] w-full overflow-hidden">
                    <img src={spintercity} alt="" className="w-full h-full object-cover " />
                  </div>
                </div>

                {/* Bus Service */}
                <div className="lg:max-w-[1102px] w-full bg-[#51545B] rounded-[10px] flex flex-wrap lg:flex-nowrap justify-center items-center p-[15px] md:p-[32px]">
                  <div className="lg:w-[50%] lg:h-full h-[160px] md:h-[300px] w-full overflow-hidden">
                    <img src={spbus} alt="" className="w-full h-full object-cover" />
                  </div>
                  <div className="flex flex-col lg:pl-[32px] lg:w-[50%]">
                    <span className="text-[24px] md:text-[30px] font-[700] mt-[20px] lg:mt-0">Stress-free group travel throughout the UK.</span>
                    <span className="pt-[10px] md:pt-[22px] text-[12px] lg:text-[16px]">Planning a trip for a large group? We make it easy! We specialize in group transfers, tours, and events, working with licensed coach operators across the United Kingdom.
                      <br />
                    </span>
                    <span className="pt-[10px] md:pt-[22px] text-[12px] lg:text-[16px]">Why choose us?
                    </span>
                    <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                    Professional Drivers: Experienced drivers with a commitment to safety and excellent service.</div>
                    <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                    24/7 Support: Our dedicated team is available around the clock to assist you.</div>
                    <div className="text-[16px] text-left text-ellipsis mt-[10px]">Variety of Vehicles: Cars, vans, minibuses, and coaches to accommodate any group size.</div>
                    <div className="text-[16px] text-left text-ellipsis mt-[10px]">Personalized Service: We tailor each trip to your specific requirements.</div>
                    <div className="text-[16px] text-left text-ellipsis mt-[10px]">Airport Coordinator: For large group airport pick-ups, we include a dedicated coordinator to meet and greet your party.</div>
                    <span className="font-semibold mt-[10px]">
                    Get a quote today!
                    </span>
                  </div>
                </div>
              </div>
            </div>


            <div className="pt-14 p-4 container-xl mx-auto">
              <div className="pb-4">
                <div className="text-[34px] md:text-[48px] pb-4 font-bold flex justify-start px-[25px] md:px-0">
                  Your reliable airport transfer service across the UK.
                  <img src={BritishIcon} width="50px" height="50px" className="ml-4" />
                </div>
                <div className="py-[20px]">
                  <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                    We operate at all London airports, including Heathrow, Gatwick, Stansted, Luton, and City Airport. We partner with licensed transport providers across the United Kingdom to offer seamless and affordable airport transfers. </div>
                </div>
                <div className="text-[34px] md:text-[48px] pb-4 font-bold flex justify-start px-[25px] md:px-0">
                  Why choose us?
                </div>
                <div className="py-[20px]">
                  <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                  Wide Network: We work with a vast network of transportation providers, ensuring you have a reliable ride wherever you travel in the UK.
                  </div>
                  <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                  Focus on Quality: We prioritize customer satisfaction and work with providers who maintain modern, high-quality vehicles
                  </div>
                  <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                  Heathrow Expertise: Heathrow is a major hub, and we have extensive experience providing efficient and comfortable transfers to and from this busy airport.
                  </div>
                  <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                  Professional Service: Our partner providers employ professional, CRB-checked chauffeurs who are committed to providing excellent service.
                  </div>
                  <div className="text-[16px] text-left text-ellipsis mt-[10px]">
                  Book your airport transfer today!
                  </div>
                </div>
              </div>
              <div className="h-92">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 h-full ">
                  {SERVICE_AIRPORT.map((item, index) => {
                    return (
                      <div className="flex items-center h-20 px-2 py-2 m-4 space-x-6 text-black bg-gray-100 rounded-lg ">
                        <div className="px-4 py-2 font-semibold rounded-lg bg-yellow-1000">
                          {index + 1}
                        </div>
                        <div className="text-base font-semibold text-left">
                          {item.name},<div>{item.name2}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>


            </div>
            <BlogListing />
            <div className="container-xl mx-auto px-[5%] pb-[50px]">
              <div
                className="flex items-center justify-between border-[#FFD369] border-[1px] py-[20px] px-[15px] md:py-[40px] md:px-[30px] max-w-[326px] mt-[40px] hover:cursor-pointer "
                onClick={() => { history.push("/blog") }}
              >
                <span className="text-[#FFD369] text-[24px] md:text-[32px]">Load More</span>
                <img src={arrow} alt="" className="h-[26px] ml-[10px]" />
              </div>
            </div>
          </div>

        </main>
      </div >
      <Footer />
    </div >
  );
};

export default Service;
