import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";

const Privacy = () => {
  return (
    <div className="flex flex-col  inner-page text-white bg-black ">
      <Navbar />
      <div className="flex flex-col items-center justify-center px-4 overflow-auto">
        <div className="px-2 pt-[10px] md:pt-4 space-x-2 justify-evenly md:w-3/5 self-center">
          <div className="my-[50px]">
            <h3 className="text-center font-bold">RIDEWING PRIVACY POLICY (Kingdom Holidays Group)</h3>
            <br />

            <p className="py-2 font-bold">
              1. Introduction
            </p>
            <p className="py-2">
              This privacy policy explains how Kingdom Holidays Group (trading as RIDEWING) ("we,"
              "us," or "our") collects, uses, and protects your personal information when you use our
              website and services. We are committed to protecting your privacy and ensuring the
              security of your data.
            </p>

            <p className="py-2 font-bold">
              2. Definitions
            </p>
            <ol className="list-disc list-inside">
              <li className="text-justify p-2 ">
                <b>Data:</b> All information you submit to Kingdom Holidays Group (trading as
                RIDEWING) via the website.
              </li>
              <li className="text-justify p-2 ">
                <b>Service:</b> The website and services provided by Kingdom Holidays Group (trading
                as RIDEWING).
              </li>
              <li className="text-justify p-2 ">
                <b>Personal Data:</b> Information that identifies a living individual.
              </li>
              <li className="text-justify p-2 ">
                <b>Usage Data:</b>  Information collected automatically from your use of the Service.
              </li>
              <li className="text-justify p-2 ">
                <b>Data Controller:</b>  The entity that determines how your data is processed.
                Kingdom Holidays Group (trading as RIDEWING) is the Data Controller for your
                data.
              </li>
              <li className="text-justify p-2 ">
                <b>Data Processors:</b>  Third parties who process data on behalf of the Data
                Controller.
              </li>
              <li className="text-justify p-2 ">
                <b>Data Subject:</b> The individual to whom the Personal Data relates.
              </li>
              <li className="text-justify p-2 ">
                <b>Website:</b> The website you are currently using, including any sub-domains.
              </li>
            </ol>

            <p className="py-2 font-bold">
              3. Scope
            </p>
            <p className="py-2">
              This privacy policy applies only to the actions of Kingdom Holidays Group (trading as
              RIDEWING) and Users with respect to this website. It does not extend to any websites
              that can be accessed from this website, including links to social media websites.
            </p>

            <p className="py-2 font-bold">
              4. Data Collected
            </p>
            <ol className="list-disc list-inside">
              <li className="text-justify p-2 ">
                <b>Personal Data:</b>  We may collect your email address, name, phone number,
                address, cookies, usage data, and location data.
              </li>
              <li className="text-justify p-2 ">
                <b>Usage Data:</b>  This includes your IP address, browser type, pages visited, time and
                date of visit, device identifiers, and other diagnostic data.
              </li>
              <li className="text-justify p-2 ">
                <b>Other Data:</b>  We may collect additional information such as gender, age, date of
                birth, passport details, citizenship, education, employment, marital status, and
                other relevant data.
              </li>
            </ol>

            <p className="py-2 font-bold">
              5. How We Collect Data
            </p>
            <p className="py-2">
              We collect data when you provide it to us, and automatically through your use of the
              Service.
            </p>

            <p className="py-2 font-bold">
              6. Use of Data
            </p>
            <p className="py-2">
              We use the collected data to provide and maintain the Service, improve user
              experience, provide customer support, for marketing and promotional purposes, and to
              comply with legal obligations.
            </p>

            <p className="py-2 font-bold">
              7. Sharing of Data
            </p>
            <p className="py-2">
              We may share your data with:
            </p>
            <ol className="list-disc list-inside">
              <li className="text-justify p-2 ">
                <b>Drivers and Admin:</b> Your name, contact number, email, and location will be
                visible to drivers and administrators to facilitate the services.
              </li>
              <li className="text-justify p-2 ">
                <b>Third-Party Service Providers:</b> We may share data with third-party service
                providers who assist us in operating our Service, such as payment processors
                and analytics providers.
              </li>
            </ol>

            <p className="py-2 font-bold">
              8. Security of Data
            </p>
            <p className="py-2">
              We strive to protect your data using commercially acceptable means, but no method of
              transmission over the internet is 100% secure.
            </p>

            <p className="py-2 font-bold">
              9. Data Retention
            </p>
            <p className="py-2">
              We will retain your Data for as long as necessary for the purposes set out in this
              Privacy Policy.
            </p>

            <p className="py-2 font-bold">
              10. Transfer of Data
            </p>
            <p className="py-2">
              Your data may be transferred to and maintained on computers located outside of your
              state, province, country, or other governmental jurisdiction.
            </p>

            <p className="py-2 font-bold">
              11. Disclosure of Data
            </p>
            <p className="py-2">
              We may disclose your data in the event of a business transaction, such as a merger or
              acquisition, or to comply with legal obligations. </p>

            <p className="py-2 font-bold">
              12. Your Rights
            </p>
            <p className="py-2">
              You have the right to access, correct, erase, restrict, or object to the processing of your
              data. You can exercise these rights by contacting us.</p>

            <p className="py-2 font-bold">
              13. California Privacy Rights
            </p>
            <p className="py-2">
              California residents have additional rights under CalOPPA and CCPA, including the right
              to know what data we collect, the right to delete your data, and the right to opt-out of
              the sale of your data.</p>

            <p className="py-2 font-bold">
              14. Service Providers
            </p>
            <p className="py-2">
              We may use third-party service providers to assist in providing and analyzing the
              Service.</p>

            <p className="py-2 font-bold">
              15. Links to Other Websites
            </p>
            <p className="py-2">
              Our Service may contain links to other websites. We are not responsible for the privacy
              practices of these websites.</p>

            <p className="py-2 font-bold">
              16. Changes of Business Ownership
            </p>
            <p className="py-2">
              In the event of a change of ownership, your data may be transferred to the new owner.</p>

            <p className="py-2 font-bold">
              17. General
            </p>
            <p className="py-2">
              This privacy policy is governed by the laws of England and Wales.</p>

            <p className="py-2 font-bold">
              18. Changes to This Privacy Policy
            </p>
            <p className="py-2">
              We may update our Privacy Policy from time to time.</p>

            <p className="py-2 font-bold">
              19. Contact Us
            </p>
            <p className="py-2">
              If you have any questions about this Privacy Policy, please contact us by email at
              <a href="mailto:info@ridewing.co.uk" className="px-2">info@ridewing.co.uk</a>
               or call us at <a href="tel:02084324325" className="pl-2">02084324325</a>.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
