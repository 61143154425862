import React from "react";
import Navbar from "../../common/Navbar";
import { Input, Textarea, Button, Accordion } from "@bigbinary/neetoui";
import Footer from "../../common/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";
import ServicesApi from "../../api/passengerDetails";
import { toast } from "react-toastify";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [submitting, setSubmitting] = useState(false); // state variable to track form submission status
  const [errorMessage, setErrorMessage] = useState("");

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Check if name, email and phone are not empty
    if (!name || !email || !phone) {
      setErrorMessage("Name, email and phone number are required.");
      return;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    // Validate phone number
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      setErrorMessage("Please enter a valid 10-digit phone number.");
      return;
    }

    // Form is valid, submit it
    // console.log("Name:", name);
    // console.log("Email:", email);
    // console.log("Phone number:", phone);
    // console.log("Note:", note);

    // show loading indicator while submitting the form
    setSubmitting(true);

    const requestBody = {
      email,
      name,
      phone: parseInt(phone),
      note,
    };

    const res = await ServicesApi.ContactUsEmail(requestBody);
    // console.log(res);
    // if (res) {
    toast.success("Form submitted successfully!");
    setName("");
    setEmail("");
    setPhone("");
    setNote("");
    // }
  };

  return (
    <div className="bg-black">
      <div className="flex flex-col bg-black">
        <Navbar />
        <main className="main">
          <div className="container container-xl lg:flex lg:flex-wrap">
            <div className="lg:pr-4 lg:py-16 justify-items-start lg:w-1/2">
              <h1 className="h2 text-center">Contact Us</h1>
              {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              <form
                className="content-wrap space-y-4 mt-6 lg:mt-8 form"
                onSubmit={handleFormSubmit}
              >
                <div>
                  <Input
                    required
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <Input
                    required
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <Input
                    required
                    label="Phone Number"
                    type="tel"
                    pattern="[0-9]+"
                    title="Please enter a valid phone number (digits only)"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div>
                  <Textarea
                    label="Note"
                    placeholder=""
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
                <div className="flex justify-center">
                  <Button
                    className="btn-register"
                    label="Submit"
                    type="submit"
                  />
                </div>
              </form>
            </div>
            <div className="py-16 lg:pl-4 lg:w-1/2">
              <h2 className="text-center">FAQ</h2>
              <div className="content-wrap mt-6 lg:mt-14 faq">
                <Accordion iconProps="text-white">
                  <Accordion.Item title="Will I be charged for cancelling my booking?">
                    Free cancellation if, cancellation made more than 24 hours
                    before the transfer time; We will charge 10% administration
                    fee for the refund as this processing (interchange) fee,
                    charged by merchant account. 50% cancellation charge if
                    cancellation made between 24 hhours & 12 hours before pick
                    up time, 100% cancellation charge if cancellation made
                    within 12 hours of pick up time. Refunds could take up to 15
                    working days to process.
                  </Accordion.Item>
                </Accordion>
                <Accordion iconProps="text-white">
                  <Accordion.Item title="How do I cancel/modify a booking?">
                    <p>
                      You can call us on +44 0208 432 432 5 or fill the contact
                      us form on our website
                    </p>
                    <p>
                      If you do not received an email from us confirming the
                      cancellation ,then we have not aware of the cancellation
                      ,in this case please give us a call or whats up on our out
                      of hours number which is +44 07 500 200 570 as soon as
                      possible.{" "}
                    </p>

                    <p>
                      To make any changes to your booking, please use our
                      contact form with your booking reference number or call
                      our office on +44 0208 432 432 5 and we will be more than
                      happy to make any changes to your booking.{" "}
                    </p>
                  </Accordion.Item>
                </Accordion>
                <Accordion iconProps="text-white">
                  <Accordion.Item title=" I was charged with an incorrect amount. What should I do?">
                    We are happy to help immediately. Call our office on or use
                    our contact form including the pick-up and drop-off location
                    as well as the price that you received.
                  </Accordion.Item>
                </Accordion>
                <Accordion iconProps="text-white">
                  <Accordion.Item title=" How do I make a complaint?">
                    If you have a complaint, please fill out our contact form
                    with as much information as possible about why we did not
                    meet your expectations and we will get back to you promptly
                    with an aim to improve and correct any errors.
                  </Accordion.Item>
                </Accordion>
                <Accordion iconProps="text-white">
                  <Accordion.Item title=" How will  I recognise my driver at the airport or train station?">
                    For airport and train station pick-ups, your driver will be
                    waiting for you at the arrivals area with a name board with
                    your name and our company logo.
                  </Accordion.Item>
                </Accordion>
                <Accordion iconProps="text-white">
                  <Accordion.Item title="What if my flight or train is delayed?">
                    We understand that unexpected delays can happen. If you
                    provide us your flight or train details upon booking, we
                    will be able to track your flight/train and arrive promptly
                    if you are early, on time or late. There is a 60 minute free
                    waiting time at airport pick up and 30 minute train station
                    pickup 15 minute for all other pick up.
                  </Accordion.Item>
                </Accordion>
                <Accordion iconProps="text-white">
                  <Accordion.Item title="When will i get the contact details of my driver?">
                    You will receive email contains photo id with the name and
                    phone number of your driver 6 hours prior to pick-up time.
                    If booked with a third party, and have entered the contact
                    details of a passenger, they will receive these details as
                    well.
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="lg:pt-8">
            <h2 className="text-center pb-6">Our Details</h2>
            <div className="address">
              <div className="container container-xl space-y-2 text-white address__text-wrap">
                <div className="address__text-wrap-inner">
                  <h3>Ridewing</h3>
                  <div className="pt-2 pb-4 lg:pt-4 lg:pb-6">
                    <p>Ridewing is the trading name of Kingdom Holidays Group.</p>
                    <p>CRN-11730934, Registered in England & Wales.</p>
                    <p>Company Registration Number-11730934</p>
                    <p>VAT-313788488 </p>
                    {/* | Licence Number-0101690102 */}
                  </div>
                  <address className="pb-6 lg:pb-10">
                    <p>491 - Sipson Road, West Drayton, UB7 0JB, UK</p>
                    <p>
                      Contact:{" "}
                      {/* <a href="tel:+44 333 444 1248">+44 333 444 1248</a> |{" "} */}
                      <a href="tel:+44 208 432 4325">+44 208 432 4325</a>
                    </p>
                    <p>
                      Email:{" "}
                      <a href="mailto:info@ridewing.co.uk">
                        info@ridewing.co.uk
                      </a> 
                      {/* |{" "}
                      <a href="mailto:global.operations@ridewing.co.uk">
                        global.operations@ridewing.co.uk
                      </a> */}
                    </p>
                  </address>
                  <div className="pb-4 lg:pb-6">
                    <Link to="/terms-and-conditions" target="_blank">
                      Terms & Conditions
                    </Link>
                  </div>
                  <div className="pb-4 lg:pb-6">
                    <Link to="/privacy-policy" target="_blank">
                      Privacy Policy & GDPR
                    </Link>
                  </div>
                  <div>
                    <Link to="/refund-policy" target="_blank">
                      Refund Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
